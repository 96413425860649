<script setup lang="ts">
import { PropType } from 'vue';
import { ref } from 'vue';
import CommonInput from "~/components/Common/Input.vue"

type Position = "top" | "bottom" | "full"
type InputType = "text" | "password" | "number" | "email"

const props = defineProps({
    hasError: {
        type: Boolean,
        default: false
    },
    label: {
        type: String
    },
    mandatory: {
        type: Boolean,
        default: false
    },
    name: {
        type: String,
        default: ""
    },
    position: {
        type: String as PropType<Position>
    },
    modelValue: {
        type: String,
        default: ""
    },
    placeholder: {
        type: String,
        default: ""
    },
    autocomplete: {
        type: String,
    },
    readonly: {
        type: Boolean,
        default: false
    },
    iconOn: {
        type: String,
    },
    iconOff: {
        type: String,
    },
    iconState: {
        type: Boolean,
        default: true
    },
    type: {
        type: String as PropType<InputType>,
        default: "text"
    }
})

const emits = defineEmits(['input', 'update:modelValue', 'iconOn', 'iconOff', 'keypressEnter'])

const iconEnabled = ref(props.iconState)

function iconOnClick() {
    iconEnabled.value = false
    emits('iconOn')
}

function iconOffClick() {
    iconEnabled.value = true
    emits('iconOff')
}


</script>
<template>
    <div class="bg-stone-100 w-full p-2 pb-0 border-0 border-b
                 border-b-stone-200" :class="{
                     'bg-red-100': hasError,
                     'rounded-xl': position == 'full',
                     'rounded-t-xl': position == 'top',
                     'rounded-b-xl': position == 'bottom'
                 }">
        <div v-if="label" class="text-xs font-medium text-stone-500" :class="{ 'text-red-600': hasError }">
            {{ label }}<span v-if="mandatory">*</span>
        </div>
        <div class="flex items-center">
            <CommonInput :model-value="modelValue" :name="name" theme="start" :type="type" :placeholder="placeholder"
                :readonly="readonly" @update:model-value="$emit('update:modelValue', $event)"
                :autocomplete="autocomplete" @keypress-enter="$emit('keypressEnter')">
            </CommonInput>
            <Icon v-if="(iconEnabled && iconOn) || !iconOff" :icon="iconOn" class="mx-2 cursor-pointer  text-stone-500"
                @click="iconOnClick" />
            <Icon v-if="!iconEnabled && iconOff" :icon="iconOff" class="mx-2 cursor-pointer  text-stone-500"
                @click="iconOffClick" />
        </div>
    </div>
</template>
<style scoped>

</style>